
.cls-banner-content {
    width              : 100% !important;
    background-image   : url("../images/banner.webp");
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
  }
.cls-banner-content .row {
    position: relative;
    height  : 600px;
    width: 100%;
  }
  
  .cls-banner-content .row .col-lg-12 {
    position : relative;
    top      : 200px;
    left     : 150px;
    max-width: 580px;
  }
  
  .cls-banner-content p {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 800;
    font-size  : 50px;
    line-height: 50px;
    color      : #FFFFFF;
    text-align : left;
  }
  
  .cls-banner-content span {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: normal;
    font-size  : 24px;
    line-height: 150%;
    color      : #FFFFFF;
  }