.contact-us {
    padding-bottom  : 75px;
    margin-top      : 75px;
    background-color: #EEF5FF;
    text-align      : center;
    .horizontal-line {
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 10px;
        height: 2px;
        width: 100px;
        outline: none;
        background-color: #1796E4;
        background-image: linear-gradient(to right, #1796E4, #fff);
    }
    .title-section {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 59px;
        text-align: center;
        text-transform: capitalize;
        color: #1796E4;
        padding-top: 100px;
    }
  }
  
  .contact-us .row {
    margin-top: 35px;
  }
  
  .contact-us .row>div {
    padding: 0;
  }
  
  .contact-us .row>div:nth-child(2) {
    border-right: 3px solid #FFFFFF;
    border-left : 3px solid #FFFFFF;
  }
  
  .contact-us .row img {
    width        : 40px;
    height       : 40px;
    margin-bottom: 22px;
  
  }
  
  .contact-us .row span {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 500;
    font-size  : 16px;
    line-height: 20px;
    text-align : center;
    color      : #434343;
  }