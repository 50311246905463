.reason {
    background         : #EEF5FF;
    background-image   : url("../../images/bg-reason.webp");
    background-position: center center;
    background-repeat  : no-repeat;
    width              : 100% !important;
    background-size    : contain;
    padding-bottom     : 100px;
    .horizontal-line {
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 10px;
        height: 2px;
        width: 100px;
        outline: none;
        background-color: #1796E4;
        background-image: linear-gradient(to right, #1796E4, #fff);
    }
    .title-section {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 59px;
        text-align: center;
        text-transform: uppercase;
        color: #1796E4;
        padding-top: 100px;
    }
  }
  
  .reason .row {
    text-align    : center;
    max-width     : 1210px;
    margin        : 0 auto;
    margin-top    : 60px;
    padding-bottom: 50px;
  }
  
  .reason .row>div {
    background   : #FFFFFF;
    box-shadow   : 0px 1px 13px rgba(47, 118, 160, 0.2);
    border-radius: 20px;
    width        : 280px;
    height       : 309px;
    padding      : 27px 19px 40px;
    margin-right : 30px;
  }
  
  .reason .row>div:last-child {
    margin-right: 0;
  }
  
  .reason .row img {
    border       : 2px solid #CBE0FF;
    box-sizing   : border-box;
    border-radius: 50%;
  }
  
  .reason .row p {
    font-family   : 'Montserrat';
    font-style    : normal;
    font-weight   : 700;
    font-size     : 20px;
    line-height   : 24px;
    text-align    : center;
    text-transform: capitalize;
    color         : #434343;
    margin        : 21px auto 9px;
  }
  
  .reason .row span {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: normal;
    font-size  : 18px;
    line-height: 150%;
    text-align : center;
    color      : #333333;
  }