
.typical-solutions{
    .horizontal-line {
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 10px;
        height: 2px;
        width: 100px;
        outline: none;
        background-color: #1796E4;
        background-image: linear-gradient(to right, #1796E4, #fff);
    }
    .title-section {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 59px;
        text-align: center;
        text-transform: capitalize;
        color: #1796E4;
        padding-top: 100px;
    }
    .img-slider{
        width: 90% !important;
    }
}

.typical-solutions .sub-title {
    font-family  : 'Montserrat';
    font-style   : normal;
    font-weight  : bold;
    font-size    : 32px;
    line-height  : 39px;
    color        : #0094EE;
    margin-bottom: 15px;
    text-transform: uppercase;
  
  }
  
  .typical-solutions .sub-desciption {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 500;
    font-size  : 20px;
    line-height: 150%;
    color      : #333333;
  }
  
  .typical-solutions .row {
    margin-top: 34px;
  
  }
  
  .typical-solutions .row ul {
    padding-left: 12px;
  }
  
  .typical-solutions .row ul li {
    list-style-type: none;
    border-left    : 1px dashed #0094EE;
    display        : flex;
  }
  
  .typical-solutions .row ul li .check {
    width   : 23px;
    height  : 23px;
    position: relative;
    left    : -12px;
  }
  
  .typical-solutions .row ul li span {
    font-family   : 'Montserrat';
    font-style    : normal;
    font-weight   : 500;
    font-size     : 18px;
    line-height   : 150%;
    color         : #333333;
    position      : relative;
    padding-left  : 12px;
    padding-bottom: 16px;
  }
  
  .typical-solutions .row ul li:last-child span {
    padding-bottom: 0;
  }
  
  .typical-solutions .row ul li:last-child img {
    position: relative;
    top     : 5px;
  }
  
  .typical-solutions .d-flex {
    display: flex;
  }
  
  .typical-solutions .order {
    order: 2;
  }
  
  .typical-solutions .border {
    border       : 4px solid #E5F4FD !important;
    box-sizing   : border-box;
    border-radius: 20px;
    max-width    : 1280px;
    margin       : 0 auto;
    padding      : 60px 40px;
  }
  
  .typical-solutions .border-right {
    border-right : none !important;
    position     : relative;
    top          : -45px;
    margin-bottom: -45px;
  }
  
  .typical-solutions .border-left {
    border-left  : none !important;
    position     : relative;
    top          : -45px;
  }
  
  .typical-solutions .height {
    height: 480px;
  }
  
  .typical-solutions .sub-robot {
    padding-bottom: 40px;
  }
  
  .typical-solutions>div:nth-child(3) {
    margin-top: 100px;
  }
  
  .typical-solutions>div .sub-title {
    margin-top: 30px;
  }