body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400 !important;
  font-size: 22px !important;
  scroll-behavior: unset !important;
}
html {
  scroll-behavior: unset !important;
}
main {
  min-height: calc(100vh - 80px);
}

.swiper-pagination {
  position: relative !important;
  bottom: -8px !important;
  left: -20px !important;
}
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  margin: 5px 7px !important;
  background: #abdcfb !important;
  -webkit-backface-visibility: visible !important;
  transition: opacity 0.2s ease !important;
  border-radius: 30px !important;
  opacity: unset !important;
}
.swiper-pagination-bullet-active {
  background-color: #0094ee !important;
}


