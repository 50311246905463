.screenshots .images {
  width: 1220px;
  margin: 0px auto;
  height: 390px;
  margin-top: 50px;
  border: 4px solid #e5f4fd;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 70px 80px 10px;
  position: relative;
}
.screenshots {
  .horizontal-line {
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 2px;
    width: 100px;
    outline: none;
    background-color: #1796e4;
    background-image: linear-gradient(to right, #1796e4, #fff);
  }
  .title-section {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 59px;
    text-align: center;
    text-transform: uppercase;
    color: #1796e4;
    padding-top: 100px;
  }
  .img-slider {
    width: 100%;
    height: auto;
  }
  .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 300;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0094ee;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #ffffff;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .iconleft3 {
    top: 45%;
    position: absolute;
    z-index: 999;
    left: 10px;
    width: 100px;
  }
  .iconright3 {
    top: 45%;
    position: absolute;
    z-index: 999;
    right: 10px;
    width: 60px;
  }
  .button {
    cursor: pointer;
  }
  .swiper {
    height: 100% !important;
  }
  .swiper-slide-active {
    -webkit-transform: scale(1.1) !important;
    transform: scale(1.1) !important;
  }
  .swiper-pagination {
    bottom: 50px !important;
  }
}

@media screen and (max-width: 1200px) {
  .screenshots .images {
    width: 100%;
  }
}
