.our-solution {
    width     : 100% !important;
    margin    : 0 auto;
    text-align: center;
    min-height: 400px;
    position: relative;
    height: 595px;
    .title-section {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 59px;
        text-align: center;
        text-transform: capitalize;
        color: #1796E4;
        padding-top: 100px;
    }
    .horizontal-line {
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 10px;
        height: 2px;
        width: 100px;
        outline: none;
        background-color: #1796E4;
        background-image: linear-gradient(to right, #1796E4, #fff);
    }
  }
  
  .our-solution .item {
    max-width    : 260px !important;
    height       : 286px;
    border       : 3px solid #E5F4FD;
    border-radius: 20px;
    padding      : 42px 54px;
    margin-right : 29px;
    background   : #FFFFFF;
  }
  
  .our-solution .row>div:last-child {
    margin-right: 0;
  }
  
  .our-solution .row {
    max-width : 1130px;
    margin    : 0 auto;
    position  : relative;
    z-index   : 999;
    margin-top: 60px;
  }
  
  .our-solution .item-title {
    font-family   : 'Montserrat';
    font-style    : normal;
    font-weight   : bold;
    font-size     : 20px;
    line-height   : 24px;
    text-align    : center;
    text-transform: capitalize;
    color         : #1796E4;
    margin-top    : 22px;
    position      : relative;
    z-index       : 999;
  }
  
  .our-solution img {
    width   : 130px;
    height  : 130px;
    position: relative;
    z-index : 999;
  }
  
  .our-solution .blue-bg {
    background-image: url("../images/blue-bg.webp");
    background-size : 100% 227px;
    height          : 227px;
    position        : relative;
    top             : -210px;
    z-index         : 0;
    border-radius   : 20px;
    margin-bottom   : -210px;
    max-width       : 1280px;
  }