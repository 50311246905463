.about-us {
  width: 100% !important;
  .title-section {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 59px;
    text-align: center;
    text-transform: capitalize;
    color: #1796e4;
    padding-top: 100px;
    text-transform: uppercase;
  }
  .horizontal-line {
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 2px;
    width: 100px;
    outline: none;
    background-color: #1796E4;
    background-image: linear-gradient(to right, #1796E4, #fff);
}
}

.about-us .bg-about-us {
  background-image: url("../images/bg-about-us.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

.about-us .row {
  max-width: 1055px !important;
  margin: 0 auto;
}

.about-us .row > div:first-child {
  text-align: left;
  margin-top: 120px;
  padding-left: 0;
}

.about-us .row > div:last-child {
  width: 760px;
  float: right;
}

.about-us .row .material-icons {
  /* font-family: Arial;
    font-style : normal; */
  font-weight: 900;
  font-size: 96px;
  line-height: 144px;
  text-align: justify;
  color: #ffffff;
}

.about-us .rotate {
  transform: rotate(-180deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(180deg);
  /* Firefox */
  -moz-transform: rotate(-180deg);
  /* IE */
  -ms-transform: rotate(-180deg);
  /* Opera */
  -o-transform: rotate(-180deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.about-us .float-right {
  float: right;
}

.about-us .row img {
  width: 283px;
  height: 281px;
  margin-top: -30px;
}

.about-us .row .content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: justify;
  color: #ffffff;
}

.about-us .row p:last-child {
  margin-top: 20px;
}
